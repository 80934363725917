import { Box, Stack, Text, Image, Spacer, Button } from "@chakra-ui/react"

const ComplianceFeatureCard = ({ children, ...props }) => {
  return (
    <Box
      background="linear-gradient(45deg, var(--landing-page-light-purple) 0%, var(--landing-page-dark-purple) 50%, var(--landing-page-light-purple) 100%)"
      padding="0 54px"
      border="1px solid var(--website-color-border-dark)"
      borderRadius="10px"
      height="100%"
      {...props}
    >
      {children}
    </Box>
  )
}

const ComplianceFeatureCardTitle = ({ children, ...props }) => {
  return (
    <Text color="#FFF" fontSize="24px" fontWeight="bold" {...props}>
      {children}
    </Text>
  )
}

const ComplianceFeatureCardDescription = ({ children, ...props }) => {
  return (
    <Text color="#9296AD" {...props}>
      {children}
    </Text>
  )
}

enum ComplianceCapabilityScreenshots {
  WorkflowManagement = "https://imgix.cosmicjs.com/1df8b610-63c5-11ee-b975-cb0cfadd93ad-WorkflowManagement.svg",
  DataDocumentation = "https://imgix.cosmicjs.com/83f68630-63c6-11ee-b975-cb0cfadd93ad-DataDocumentation.svg",
  SyntheticSandbox = "https://imgix.cosmicjs.com/1deb2180-63c5-11ee-b975-cb0cfadd93ad-SyntheticSandbox.svg",
}

const KnowYourRisksSection = () => {
  return (
    <Box display={["none", "block"]} background="var(--landing-page-darkest-purple)">
      <Box maxWidth="1120px" width="90%" margin="auto" paddingTop="94px" paddingBottom="96px">
        <Text
          align="center"
          fontSize="54px"
          fontWeight="var(--website-font-weight-h1)"
          width={["100%", "90%", "80%"]}
          lineHeight="1.2"
          margin="auto"
          marginBottom="16px"
          color="#fff"
          letterSpacing={["-0.72px", "-1.62px", "-1.62px"]}
        >
          Safely Leverage Sensitive Healthcare Data at Unprecedented Speeds
        </Text>
        <Text fontSize="18px" color="#9296AD" align="center" width={["100%", "90%", "75%"]} margin="auto" marginBottom="56px">
          Our technology makes dataset creation easier for pharmaceutical companies, payers and healthcare providers by automating the process for data de-identification and compliance certification.
        </Text>

        <Stack direction="column" spacing="36px" marginTop="40px">
          <ComplianceFeatureCard>
            <Stack direction="row" spacing="24px">
              <Stack justifyContent="center">
                <ComplianceFeatureCardTitle>Workflow Management</ComplianceFeatureCardTitle>
                <ComplianceFeatureCardDescription>
                  Collaborate with the users, stakeholders, and authorized personnel you need to throughout certification initiatives. Assign tasks and due dates, with system notifications to keep on
                  schedule - all within the framework of each certification&apos;s specific requirements.
                </ComplianceFeatureCardDescription>
                <div style={{ paddingTop: "32px", display: "flex", flexDirection: "column" }}>
                  <Button
                    alignSelf="flex-start"
                    fontSize="14px"
                    fontWeight="var(--website-font-weight-h3)"
                    color="white"
                    bg="rgba(240, 233, 255, 0.07)"
                    border="1px solid var(--website-color-button-border)"
                    borderRadius={20}
                    display={["none", "block", "block"]}
                    paddingBlock="12px"
                    paddingInline="24px"
                    _hover={{
                      backgroundColor: "var(--secondary)",
                      color: "var(--secondary5)",
                    }}
                    onClick={() => {
                      location.href = "/how-it-works"
                    }}
                  >
                    Learn More
                  </Button>
                </div>
              </Stack>
              <Image src={ComplianceCapabilityScreenshots.WorkflowManagement} marginLeft="20px" marginTop="64px" marginBottom="64px" alt="Workflow Management feature screenshot" />
            </Stack>
          </ComplianceFeatureCard>

          <Stack direction={["column", "column", "row"]} spacing="36px">
            <Box width="40%">
              <ComplianceFeatureCard>
                <Stack direction="column" spacing="8px">
                  <Image marginTop="64px" src={ComplianceCapabilityScreenshots.DataDocumentation} alt="Data Documentation feature screenshot" />
                  <Spacer /> <Spacer />
                  <ComplianceFeatureCardTitle>Data Documentation</ComplianceFeatureCardTitle>
                  <ComplianceFeatureCardDescription>Define datasets and use cases, stored centrally and version-controlled in Integral&apos;s data privacy suite.</ComplianceFeatureCardDescription>
                </Stack>
              </ComplianceFeatureCard>
            </Box>
            <ComplianceFeatureCard>
              <Stack direction="column" spacing="12px" paddingTop="48px">
                <ComplianceFeatureCardTitle>Synthetic Sandbox</ComplianceFeatureCardTitle>
                <ComplianceFeatureCardDescription>Confirm the compliant version of the data can satisfactorily achieve the commercial use case needs.</ComplianceFeatureCardDescription>
                <Spacer /> <Spacer />
                <Image src={ComplianceCapabilityScreenshots.SyntheticSandbox} alt="Synthetic Sandbox feature screenshot" />
              </Stack>
            </ComplianceFeatureCard>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

export default KnowYourRisksSection
