import { VStack, Text, Box, HStack, Image, Flex, Hide } from "@chakra-ui/react"

import { CloudImages } from "lib/cosmic/images"

const EnablingCollaboration = () => {
  const StatCards = [
    {
      title: "147B+",
      subtitle: "Records under management - and growing",
      icon: CloudImages.ENABLING_COLLABORATION_RECORDS_UNDER_MANAGEMENT_ICON,
    },
    {
      title: "92%",
      subtitle: "Reduction in time to Expert Determination certification",
      icon: CloudImages.ENABLING_COLLABORATION_REDUCTION_IN_TIME_ICON,
    },
  ]

  return (
    <Hide below="md">
      <VStack justifyContent="space-around" width="100%" backgroundColor="#FBFCFF" position="relative">
        <Text
          fontSize={["var(--website-font-size-h1-mobile)", "var(--website-font-size-h1-mobile)", "var(--website-font-size-h1)"]}
          textAlign="center"
          lineHeight="102%"
          maxWidth="800px"
          marginBottom="104px"
          marginTop="72px"
        >
          Enabling Collaboration with Sensitive Data
        </Text>
        <HStack spacing="192px" zIndex="1" position="relative">
          {StatCards.map((card, index) => (
            <Box
              key={index}
              padding="36px 48px"
              boxShadow="1.141px 4.565px 32.643px 0px #F2EFFF"
              border="1.282px solid #BFE4DC"
              borderRadius="44px"
              width="416px"
              backgroundColor="white"
              position="relative"
            >
              <Text fontSize="56px">{card.title}</Text>
              <Text fontSize="var(--website-font-size-p)" width="280px">
                {card.subtitle}
              </Text>
              <Flex
                boxSize="76px"
                alignItems="center"
                justifyContent="center"
                backgroundColor="#C8EFE6"
                borderRadius="12px"
                position="absolute"
                top="-20px"
                right="-1px"
                boxShadow="2.83px 3.78px 13.359px rgba(0, 0, 0, 0.1), 0.46px 1.85px 6.605px rgba(242, 239, 255, 1)"
              >
                <Image src={card.icon} w="36px" alt={`${card.title} Icon`} />
              </Flex>
            </Box>
          ))}
        </HStack>
        <Image src={CloudImages.ENABLING_COLLABORATION_DECORATIVE_RECTANGLE} alt="Decorative Element" marginTop="-136px" height="220px" zIndex="0" pointerEvents="none" />
      </VStack>
    </Hide>
  )
}

export default EnablingCollaboration
