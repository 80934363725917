import { Box, Flex, VStack, Text, HStack, Image, Stack, Hide, Show } from "@chakra-ui/react"

import { CloudImages } from "lib/cosmic/images"

import CTAButton, { CTAButtonAction } from "./CTAButton"

const HomeJumbotronV2 = () => {
  return (
    <VStack align="stretch" spacing={0}>
      <Flex
        padding={["40px", 0, 0]}
        paddingTop={["20px", "40px", "100px"]}
        paddingBottom={["66px", "40px", "144px"]}
        flexDirection="column"
        backgroundImage="var(--website-gradient-background-image)"
        alignItems={["left", "center", "center"]}
        position="relative"
      >
        <HStack maxWidth="var(--website-width-max)" zIndex={["0", "1"]}>
          <Box>
            <Text color="white" fontWeight="var(--website-font-weight-h1)" fontSize={["44px", "56px"]} lineHeight="116%" marginBottom="12px" marginTop={["32px", "0", "0"]} w={["100%", "90%", "90%"]}>
              Maximize Data Utility,{" "}
              <span
                style={{
                  color: "#C5A3FF",
                }}
              >
                Protect Privacy
              </span>
            </Text>
            <Text color="var(--website-color-p)" fontSize="20px" textAlign="left" w={["100%", "90%", "85%"]} marginTop="16px">
              Accelerate insights with automated compliance for regulated data.
            </Text>
            <Stack spacing={["0px", "24px"]} marginTop={["34px", "54px"]} direction="row">
              <CTAButton action={CTAButtonAction.BookDemo} label="Connect With Us" muted />
              <Show above="md">
                <CTAButton action={CTAButtonAction.PlayVideo} label="See How It Works" />
              </Show>
            </Stack>
          </Box>
          <Hide below="md">
            <Box fontSize="40px" color="white">
              <Image src={CloudImages.HERO_CERTIFICATION_COMPLETE_VISUAL} alt="Certification Complete" pointerEvents="none" maxWidth="408px" />
            </Box>
          </Hide>
        </HStack>
        <Hide below="md">
          <Image src={CloudImages.HERO_ELLIPSE_DECORATION} alt="Decorative element" pointerEvents="none" position="absolute" right="0px" bottom="0px" width="33vw" maxWidth="600px" zIndex="0" />
        </Hide>
      </Flex>
    </VStack>
  )
}

export default HomeJumbotronV2
