import { Stack, Hide, Show } from "@chakra-ui/react"

import CapabilitiesDesktop from "./CapabilitiesDesktop"
import CapabilitiesMobile from "./CapabilitiesMobile"

const CapabilitiesSection = () => {
  return (
    <Stack margin="40px auto" width={["80%", "70%", "90%"]} maxWidth="var(--website-width-max)" alignItems="baseline" direction={["column", "column", "row"]} spacing={["16px", "24px", "64px"]}>
      <Hide below="md">
        <CapabilitiesDesktop />
      </Hide>
      <Show below="md">
        <CapabilitiesMobile />
      </Show>
    </Stack>
  )
}

export default CapabilitiesSection
