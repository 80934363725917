import { Spacer } from "@chakra-ui/react";
import Head from "next/head";
import { SeoTags } from "src/components/SeoTags";
import BookDemoSection from "src/components/Website/BookDemo";
import CapabilitiesSection from "src/components/Website/CapabilitiesSection";
import CustomerCallout from "src/components/Website/CustomerCallout";
import KnowYourRisksSection from "src/components/Website/KnowYourRisksSection";
import NewsSection from "src/components/Website/NewsSection";
import WebsiteLayout from "src/layouts/WebsiteLayout";
import { getPressReleases } from "lib/cosmic/pressReleases";
import { logger } from "logger";
import HomeJumbotronV2 from "src/components/Website/HomeJumbotronV2";
import TrustedBy from "src/components/Website/TrustedBy";
import EnablingCollaboration from "src/components/Website/EnablingCollaboration";
import LeverageSection from "src/components/Website/LeverageSection";
const IndexPage = ({
  pressReleases
}) => {
  return <>
      <Head>
        <SeoTags description="Compliantly connecting health data as securely and as quickly as possible." title="Integral | Expert Certifications-as-a-Service" image="/images/og_img.png" />
      </Head>

      <Spacer height="70px" background="var(--website-color-background-base)" />
      <HomeJumbotronV2 />
      <TrustedBy />
      <EnablingCollaboration />
      <CapabilitiesSection />
      <BookDemoSection />
      <CustomerCallout />
      <KnowYourRisksSection />
      <LeverageSection />
      {pressReleases?.length > 0 && <NewsSection pressReleases={pressReleases} />}
    </>;
};
export async function getServerSideProps() {
  logger.info(`Fetching press releases`);
  const pressReleases = await getPressReleases();
  return {
    props: {
      pressReleases
    }
  };
}
IndexPage.suppressFirstRenderFlicker = true;
IndexPage.getLayout = page => <WebsiteLayout title="Integral | Expert Certifications-as-a-Service">{page}</WebsiteLayout>;
export default IndexPage;